import { useEffect } from "react";

const BackgroundImageWrapper = ({ isLight, children }) => {
    useEffect(() => {
        const appElem = document.querySelector(".App");
        if (isLight) {
            appElem.classList.add("light-bg");
            appElem.classList.remove("dark-bg");
        } else {
            appElem.classList.add("dark-bg");
            appElem.classList.remove("light-bg");
        }
    }, [isLight]);

    return <>{children}</>
};

export default BackgroundImageWrapper;