import { Dialog } from "@mui/material";
import AppButton from "../../Common/AppButton";
import Lottie from "lottie-react";
import confettiAnimation from "../../../assets/animations/confetti.json";

const SuccessRegistrationModal = ({ open, handleDialogClose }) => {
  return (
    <Dialog open={open} className="register-success">
      <div className="relative flex flex-col justify-center items-center bg-primary-gradient text-white h-full overflow-hidden px-12 py-10 gap-6">
        <img src="/Assets/Icons/tickmark.svg" alt="tickmark" />
        <span className="text-lg md:text-xl font-medium text-center">
          {"Congratulations, you're in!"}
        </span>
        <span className="text-sm text-center">
          {
            "Your password will be shared over WhatsApp. Login to play a demo and get set!"
          }
        </span>
        <AppButton
          type="button"
          className="self-center z-10"
          onClick={handleDialogClose}
          style={{ minWidth: "120px", height: "40px" }}
        >
          {"Login"}
        </AppButton>
        <Lottie
          animationData={confettiAnimation}
          loop={false}
          className="absolute h-full w-full top-0 z-0"
        />
      </div>
    </Dialog>
  );
};

export default SuccessRegistrationModal;