export const IndianNames = [
  "Aarav",
  "Aditi",
  "Advait",
  "Aishwarya",
  "Akshay",
  "Amara",
  "Ananya",
  "Arjun",
  "Avani",
  "Ayush",
  "Bhavya",
  "Dev",
  "Divya",
  "Eesha",
  "Eshan",
  "Gaurav",
  "Gayatri",
  "Harsh",
  "Ishaan",
  "Jaya",
  "Kavya",
  "Krish",
  "Ojas",
  "Pooja",
  "Pranav",
  "Rahul",
  "Ria",
  "Rohan",
  "Sanya",
  "Shiv",
  "Sneha",
  "Surya",
  "Tanvi",
  "Tejas",
  "Trisha",
  "Uday",
  "Vaishnavi",
  "Varun",
  "Yash",
  "Zara",
  "Aditya",
  "Aisha",
  "Arnav",
  "Ayesha",
  "Dhruv",
  "Ishita",
  "Riya",
  "Shreya",
  "Aryan",
  "Ishani",
  "Kavita",
  "Nandini",
  "Raj",
  "Samira",
  "Shivani",
  "Tanu",
  "Vivek",
  "Yamini",
  "Anita",
  "Ashwin",
  "Neel",
  "Priya",
  "Ravi",
  "Yogita",
  "Zain",
];

export const DAILY_LIMIT = 10;
export const AI_NUMBER_OF_MOVES_TO_CHECK = 3;
export const AI_COMPUTER_ID = "computer";

export const MATRIX = [
  [
    {
      id: 1,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign1.JPG?alt=media&token=65b92701-9f6b-48f1-9204-a5420f3bfb7e",
      isImage: true,
    },
    {
      id: 1,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign1.JPG?alt=media&token=65b92701-9f6b-48f1-9204-a5420f3bfb7e",
      isImage: true,
    },
    {
      id: 2,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign2.JPG?alt=media&token=04da2a80-fb3a-4f17-9f79-dcb50af808f2",
      isImage: true,
    },
    {
      id: 2,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign2.JPG?alt=media&token=04da2a80-fb3a-4f17-9f79-dcb50af808f2",
      isImage: true,
    },
  ],
  [
    {
      id: 3,
      content:
      "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign3.JPG?alt=media&token=61c86d1e-85bc-49a3-b109-d41cdd4b8d27",
      isImage: true,
    },
    {
      id: 3,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign3.JPG?alt=media&token=61c86d1e-85bc-49a3-b109-d41cdd4b8d27",
      isImage: true,
    },
    {
      id: 4,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign4.JPG?alt=media&token=de6e298b-85d9-4b05-99e0-c5812eddc4a1",
      isImage: true,
    },
    {
      id: 4,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign4.JPG?alt=media&token=de6e298b-85d9-4b05-99e0-c5812eddc4a1",
      isImage: true,
    },
  ],
  [
    {
      id: 5,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign5.JPG?alt=media&token=a17f7841-4f80-4d9b-b3d0-7ca3f09ad364",
      isImage: true,
    },
    {
      id: 5,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign5.JPG?alt=media&token=a17f7841-4f80-4d9b-b3d0-7ca3f09ad364",
      isImage: true,
    },
    {
      id: 6,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign6.JPG?alt=media&token=c135de11-ad5a-4eda-8739-034050fac445",
      isImage: true,
    },
    {
      id: 6,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign6.JPG?alt=media&token=c135de11-ad5a-4eda-8739-034050fac445",
      isImage: true,
    },
  ],
  [
    {
      id: 7,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign7.JPG?alt=media&token=0a2279c1-6706-4048-977d-4eb2bdad82bf",
      isImage: true,
    },
    {
      id: 7,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign7.JPG?alt=media&token=0a2279c1-6706-4048-977d-4eb2bdad82bf",
      isImage: true,
    },
    {
      id: 8,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign8.JPG?alt=media&token=e403e0ee-12d5-4f57-bccd-9c5fcfed0a9d",
      isImage: true,
    },
    {
      id: 8,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FDeck%205%2FSign8.JPG?alt=media&token=e403e0ee-12d5-4f57-bccd-9c5fcfed0a9d",
      isImage: true,
    },
  ],
];

export const MATRIX_PRO = [
  [
    {
      id: 1,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon1.JPG?alt=media&token=89ce5f3e-1ff9-493d-a730-91159ae70a13",
      isImage: true,
    },
    {
      id: 1,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon1.JPG?alt=media&token=89ce5f3e-1ff9-493d-a730-91159ae70a13",
      isImage: true,
    },
    {
      id: 2,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon2.JPG?alt=media&token=40398a35-d338-48a0-8f66-0c688950cf0a",
      isImage: true,
    },
    {
      id: 2,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon2.JPG?alt=media&token=40398a35-d338-48a0-8f66-0c688950cf0a",
      isImage: true,
    },
  ],
  [
    {
      id: 3,
      content:
      "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon3.JPG?alt=media&token=55d51da2-f683-4736-a2ff-566b7659739a",
      isImage: true,
    },
    {
      id: 3,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon3.JPG?alt=media&token=55d51da2-f683-4736-a2ff-566b7659739a",
      isImage: true,
    },
    {
      id: 4,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon4.JPG?alt=media&token=91286ebe-6c01-4bb7-9116-ea09ca2ee30a",
      isImage: true,
    },
    {
      id: 4,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon4.JPG?alt=media&token=91286ebe-6c01-4bb7-9116-ea09ca2ee30a",
      isImage: true,
    },
  ],
  [
    {
      id: 5,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon5.JPG?alt=media&token=10610de7-e08f-445c-9ee5-bf73173ec0f2",
      isImage: true,
    },
    {
      id: 5,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon5.JPG?alt=media&token=10610de7-e08f-445c-9ee5-bf73173ec0f2",
      isImage: true,
    },
    {
      id: 6,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon6.JPG?alt=media&token=c808bd59-61a2-4587-94a0-1fd57f98a790",
      isImage: true,
    },
    {
      id: 6,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon6.JPG?alt=media&token=c808bd59-61a2-4587-94a0-1fd57f98a790",
      isImage: true,
    },
  ],
  [
    {
      id: 7,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon7.JPG?alt=media&token=e156bfa0-0047-466d-98bc-b7d7e2438314",
      isImage: true,
    },
    {
      id: 7,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon7.JPG?alt=media&token=e156bfa0-0047-466d-98bc-b7d7e2438314",
      isImage: true,
    },
    {
      id: 8,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon8.JPG?alt=media&token=6f677c2d-34b1-4dd7-b3f7-0458fcfc4c70",
      isImage: true,
    },
    {
      id: 8,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon8.JPG?alt=media&token=6f677c2d-34b1-4dd7-b3f7-0458fcfc4c70",
      isImage: true,
    },
   
  ],
  [
    {
      id: 9,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon9.JPG?alt=media&token=372c805a-91c6-43f0-885c-3c0d6cd2ff13",
      isImage: true,
    },
    {
      id: 9,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon9.JPG?alt=media&token=372c805a-91c6-43f0-885c-3c0d6cd2ff13",
      isImage: true,
    },
    {
      id: 10,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon10.JPG?alt=media&token=04413bbb-3c5a-4050-bc20-006f1a1bfa1e",
      isImage: true,
    },
    {
      id: 10,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon10.JPG?alt=media&token=04413bbb-3c5a-4050-bc20-006f1a1bfa1e",
      isImage: true,
    },
   
  ],
  [
    {
      id: 11,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon11.JPG?alt=media&token=572721fe-b0e7-4aa3-b0db-ff9b29839daf",
      isImage: true,
    },
    {
      id: 11,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon11.JPG?alt=media&token=572721fe-b0e7-4aa3-b0db-ff9b29839daf",
      isImage: true,
    },
    {
      id: 12,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon12.JPG?alt=media&token=42eeb9e9-a59c-4e46-a11f-d45ef5cae37a",
      isImage: true,
    }, {
      id: 12,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck1%2FAppIcon12.JPG?alt=media&token=42eeb9e9-a59c-4e46-a11f-d45ef5cae37a",
      isImage: true,
    },
  ]
];



export const MATRIX_TOURNAMENT_ROUND = [
  [
    {
      id: 1,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%201.png?alt=media&token=9fb271b4-f445-411d-887d-8e8bf137b3b0",
      isImage: true,
    },
    {
      id: 1,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%201.png?alt=media&token=9fb271b4-f445-411d-887d-8e8bf137b3b0",
      isImage: true,
    },
    {
      id: 2,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%202.png?alt=media&token=310d0f83-e972-4317-b2af-5c8b564534e6",
      isImage: true,
    },
    {
      id: 2,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%202.png?alt=media&token=310d0f83-e972-4317-b2af-5c8b564534e6",
      isImage: true,
    },
  ],
  [
    {
      id: 3,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%203.png?alt=media&token=4b99cd17-8a28-43d0-957b-c82d0d4183e1",
      isImage: true,
    },
    {
      id: 3,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%203.png?alt=media&token=4b99cd17-8a28-43d0-957b-c82d0d4183e1",
      isImage: true,
    },
    {
      id: 4,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%204.png?alt=media&token=f3128e96-55f4-4725-bd48-702391edb18d",
      isImage: true,
    },
    {
      id: 4,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%204.png?alt=media&token=f3128e96-55f4-4725-bd48-702391edb18d",
      isImage: true,
    },
  ],
  [
    {
      id: 5,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%205.png?alt=media&token=8f6a206c-cd0a-4a7a-b45e-bd04a07accbd",
      isImage: true,
    },
    {
      id: 5,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%205.png?alt=media&token=8f6a206c-cd0a-4a7a-b45e-bd04a07accbd",
      isImage: true,
    },
    {
      id: 6,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%206.png?alt=media&token=42616e06-8602-43f1-89eb-f82f4eb69d6a",
      isImage: true,
    },
    {
      id: 6,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%206.png?alt=media&token=42616e06-8602-43f1-89eb-f82f4eb69d6a",
      isImage: true,
    },
  ],
  [
    {
      id: 7,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%207.png?alt=media&token=4edc63a2-75bb-47b4-b370-3cabe2732e9c",
      isImage: true,
    },
    {
      id: 7,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%207.png?alt=media&token=4edc63a2-75bb-47b4-b370-3cabe2732e9c",
      isImage: true,
    },
    {
      id: 8,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%208.png?alt=media&token=57d14667-f89b-4008-af91-fa58856a9385",
      isImage: true,
    },
    {
      id: 8,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%208.png?alt=media&token=57d14667-f89b-4008-af91-fa58856a9385",
      isImage: true,
    },
  ],
];

export const MATRIX_PRO_TOURNAMENT_ROUND = [
  [
    {
      id: 1,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%201.png?alt=media&token=9fb271b4-f445-411d-887d-8e8bf137b3b0",
      isImage: true,
    },
    {
      id: 1,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%201.png?alt=media&token=9fb271b4-f445-411d-887d-8e8bf137b3b0",
      isImage: true,
    },
    {
      id: 2,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%202.png?alt=media&token=310d0f83-e972-4317-b2af-5c8b564534e6",
      isImage: true,
    },
    {
      id: 2,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%202.png?alt=media&token=310d0f83-e972-4317-b2af-5c8b564534e6",
      isImage: true,
    },
  ],
  [
    {
      id: 3,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%203.png?alt=media&token=4b99cd17-8a28-43d0-957b-c82d0d4183e1",
      isImage: true,
    },
    {
      id: 3,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%203.png?alt=media&token=4b99cd17-8a28-43d0-957b-c82d0d4183e1",
      isImage: true,
    },
    {
      id: 4,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%204.png?alt=media&token=f3128e96-55f4-4725-bd48-702391edb18d",
      isImage: true,
    },
    {
      id: 4,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%204.png?alt=media&token=f3128e96-55f4-4725-bd48-702391edb18d",
      isImage: true,
    },
  ],
  [
    {
      id: 5,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%205.png?alt=media&token=8f6a206c-cd0a-4a7a-b45e-bd04a07accbd",
      isImage: true,
    },
    {
      id: 5,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%205.png?alt=media&token=8f6a206c-cd0a-4a7a-b45e-bd04a07accbd",
      isImage: true,
    },
    {
      id: 6,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%206.png?alt=media&token=42616e06-8602-43f1-89eb-f82f4eb69d6a",
      isImage: true,
    },
    {
      id: 6,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%206.png?alt=media&token=42616e06-8602-43f1-89eb-f82f4eb69d6a",
      isImage: true,
    },
  ],
  [
    {
      id: 7,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%207.png?alt=media&token=4edc63a2-75bb-47b4-b370-3cabe2732e9c",
      isImage: true,
    },
    {
      id: 7,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%207.png?alt=media&token=4edc63a2-75bb-47b4-b370-3cabe2732e9c",
      isImage: true,
    },
    {
      id: 8,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%208.png?alt=media&token=57d14667-f89b-4008-af91-fa58856a9385",
      isImage: true,
    },
    {
      id: 8,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%208.png?alt=media&token=57d14667-f89b-4008-af91-fa58856a9385",
      isImage: true,
    },
  ],
  [
    {
      id: 9,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%209.png?alt=media&token=5d7d8f33-d041-42e5-bef3-3b2040e04599",
      isImage: true,
    },
    {
      id: 9,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%209.png?alt=media&token=5d7d8f33-d041-42e5-bef3-3b2040e04599",
      isImage: true,
    },
    {
      id: 10,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%2010.png?alt=media&token=341c2ebc-dbac-4ccb-9805-80b93154d716",
      isImage: true,
    },
    {
      id: 10,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%2010.png?alt=media&token=341c2ebc-dbac-4ccb-9805-80b93154d716",
      isImage: true,
    },
  ],
  [
    {
      id: 11,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%2011.png?alt=media&token=e8b88f75-b7d3-4ad6-8dc6-8f722f58fa89",
      isImage: true,
    },
    {
      id: 11,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%2011.png?alt=media&token=e8b88f75-b7d3-4ad6-8dc6-8f722f58fa89",
      isImage: true,
    },
    {
      id: 12,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%2012.png?alt=media&token=2cfb8988-ed2d-4b5c-871b-b71525f9e5e4",
      isImage: true,
    },
    {
      id: 12,
      content:
        "https://firebasestorage.googleapis.com/v0/b/maidaan-921e1.appspot.com/o/Memory%20Cards%2FPro%20Deck%202%2FBio%20Icon%2012.png?alt=media&token=2cfb8988-ed2d-4b5c-871b-b71525f9e5e4",
      isImage: true,
    },
  ],
];

export const IndianStates = [
  "Delhi",
  "Pune",
  "Chandigarh",
  "Bengaluru",
  "Kolkata",
  "Mumbai",
  "Raichur",
  "Nagur",
  "Chennai",
  "Ahmedabad",
  "Indore",
  "Jaipur",
  "Lucknow",
  "Goa",
  "Shillong",
  "Patna",
  "Vishakhapatnam",
  "Bhopal",
  "Surat",
  "Meerut",
  "Agra",
  "Shimla",
  "Ludhiana",
  "Ambala",
  "Mysuru",
  "Coimbatore",
  "Thiruvanathapuram",
  "Kochi",
  "Hyderabad",
  "Noida",
  "Guntur",
  "Vijayawada",
  "Nashik",
  "Raipur",
  "Ranchi",
  "Jammu",
  "Aurangabad",
  "Alwar",
  "Cuttack",
  "Bhubaneswar",
  "Bargarh",
  "Silchar"
];

export const getRandomValueFromArray = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}
