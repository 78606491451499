import axios from 'axios';
import { signInAnonymously, signInWithCustomToken } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { firebaseAuth } from '../../firebase-config';
import useToast from '../../hooks/use-toast';
import { MEASURE } from '../../instrumentation';
import { INSTRUMENTATION_TYPES } from '../../instrumentation/types';
import AppButton from '../Common/AppButton';
import AppInput from '../Common/AppInput';
import Layout from '../Common/Layout';
import PasswordInput from '../Common/PasswordInput';
import PhoneNumberInput from '../Common/PhoneInput';
import { login } from '../../services/child';
import { db } from '../../firebase-config';
import { setDoc, doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { calculateAppHeight } from '../../Constants/Commons';

const LoginPage = () => {
    const navigate = useNavigate();
    const { ToastComponent, hideToast, showToast } = useToast();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, _] = useSearchParams();
    const redirectPathAfterLogin = searchParams.get("redirect") ?? "/lobby";
    const isDemoGame = searchParams.get("d") === "Y";
    const hideSignup = searchParams.get("s") === "0";
    const group = searchParams.get("group") ?? '';
    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors, isValid, isLoading, isSubmitting },
    } = useForm({ phoneNumber: '', password: '' });
    const [localUserId, setLocalUserId] = useState('');

    useEffect(() => {
      if (isDemoGame) {
        calculateAppHeight();
      }
      const phone = localStorage.getItem("phoneNumber");
      if (phone) {
        setValue("phoneNumber", phone);
      }
      const userId = localStorage.getItem("userId");
      if (userId && userId !== 'null') {
        navigate(redirectPathAfterLogin);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = async (formData) => {
        hideToast();
        if (isDemoGame) {
          MEASURE(
            INSTRUMENTATION_TYPES.DEMO_LOGIN,
            localUserId,
            {}
          );
          localStorage.setItem("userId", localUserId);
          mixpanel.identify(localUserId);
              mixpanel.track('Login', {
                'LoginType': 'Demo'
              })
          let url = "/tournament-lobby?d=Y";
          if (group) {
            url += `&group=${group}`;
          }
          await signInAnonymously(firebaseAuth);
          navigate(url);
          calculateAppHeight();
          return;
        }
        const phoneWithCode = `+91${formData.phoneNumber}`;
        MEASURE(
          INSTRUMENTATION_TYPES.LOGIN,
          phoneWithCode,
          {}
        );
        try {
            const data = await login({
              phoneNumber: phoneWithCode,
              password: formData.password,
            });
            const { id, token, verifiedOTP } = data;
            const userCredentials = await signInWithCustomToken(firebaseAuth, token);
            if (userCredentials.user.uid) {
              localStorage.setItem('userId', id);
              mixpanel.identify(id);
              mixpanel.track('Login', {
                'LoginType': 'Main'
              })
              if (!verifiedOTP) {
                navigate(`/register?source=lobby&redirect=${redirectPathAfterLogin}`);
                return;
              }
                navigate(redirectPathAfterLogin, { replace: true, state: { from: 'login' }});
              //}
            } else {
              showToast('Something went wrong. Please try again');
            }
        } catch (error) {
            showToast(error.response.data.message)
        }
    };

    const goToforgotPassword = () => {
      navigate("/forgot-password");
    };

    const renderHeader = () => {
      return (
        <div className="my-4 flex flex-col justify-center items-center text-white gap-4">
          <img
            src="/Assets/Images/logo-horizontal.svg"
            alt="logo-horizontal"
            className="h-8"
          />
        </div>
      );
    };


     const renderLoginScreen = () => {
       return (
         <div className="flex flex-col items-center justify-around h-full">
           {renderHeader()}
           <div className="flex flex-col gap-4 justify-center items-center mx-8 w-full max-w-xs">
             {isDemoGame ? (
               <div className="w-full flex flex-col gap-12">
                 <AppInput
                   onChange={setLocalUserId}
                   value={localUserId}
                   placeholder="Enter your name to play a demo"
                   className="text-center"
                 />
                 <AppButton
                   type="button"
                   className="self-center"
                   disabled={!localUserId}
                   onClick={handleLogin}
                 >
                   Let's Go
                 </AppButton>
               </div>
             ) : (
               <>
                 <form
                   onSubmit={handleSubmit(handleLogin)}
                   className="w-full flex flex-col gap-3"
                 >
                   <Controller
                     name="phoneNumber"
                     control={control}
                     rules={{
                       required: "Phone number is required",
                       maxLength: {
                         value: 10,
                         message: "Please enter 10 digit phone number",
                       },
                     }}
                     render={(renderProps) => {
                       const { field } = renderProps;
                       return (
                         <PhoneNumberInput
                           {...field}
                           className="!text-base text-center"
                           wrapperClassName="mb-4"
                           placeholder="Enter Phone Number"
                           //  showLabel
                           //  label="Phone number"
                           //  labelClassName="text-white"
                           error={
                             errors &&
                             errors.phoneNumber &&
                             errors.phoneNumber.message
                           }
                         />
                       );
                     }}
                   />
                   <Controller
                     name="password"
                     control={control}
                     rules={{
                       required: "Password is required",
                     }}
                     render={(renderProps) => {
                       const { field } = renderProps;
                       return (
                         <PasswordInput
                           {...field}
                           className="!text-base text-center"
                           wrapperClassName="mb-4"
                           placeholder="Enter Password"
                           error={
                             errors &&
                             errors.password &&
                             errors.password.message
                           }
                         />
                       );
                     }}
                   />
                   <AppButton
                     type="submit"
                     className="self-center w-[216px] h-12"
                     disabled={!isValid || isSubmitting || isLoading}
                     isLoading={isSubmitting}
                   >
                     Login
                   </AppButton>
                 </form>
                 <div className="text-white">
                   <span>Forgot Password?&nbsp;</span>
                   <span
                     className="text-primary-yellow underline cursor-pointer"
                     onClick={goToforgotPassword}
                   >
                     Regenerate
                   </span>
                 </div>
               </>
             )}
             <div className="h-full flex flex-col items-center gap-4 mt-2">
               {isDemoGame ? (
                 <div className="text-white">
                   Already have an account?&nbsp;
                   <span
                     className="text-primary-yellow underline"
                     onClick={() => navigate("/login")}
                   >
                     Login
                   </span>
                 </div>
               ) : (
                 <></>
               )}
               {!hideSignup && (
                 <div className="text-white">
                   {isDemoGame ? "Register for Tournaments?" : "New to Maidaan?"}
                   &nbsp;
                   <span
                     className="text-primary-yellow underline cursor-pointer"
                     onClick={() => navigate("/register")}
                   >
                     Sign up
                   </span>
                 </div>
               )}
             </div>
           </div>
         </div>
       );
     };

    return (
      <Layout>
        <div className="flex flex-col h-full w-full">
          <img
            src="/Assets/Images/cover-image.jpg"
            alt="boy"
            className="h-[50%] sm:h-[60%] sm:object-bottom object-cover object-center"
          />
          <div className="max-w-md h-full w-full m-auto">
            <div className="flex flex-col justify-between h-full w-full">
              {renderLoginScreen()}
            </div>
          </div>
        </div>
        <ToastComponent />
      </Layout>
    );
}

export default LoginPage;