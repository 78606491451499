import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-config";

export const setTrialWords = async (quizColl, baseDifficulty = 0) => {
  const data = [];
  if (baseDifficulty === 0 || !baseDifficulty) {
    const q = query(collection(db, quizColl), where("isTrial", "==", true));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  } else {
    const q = query(
      collection(db, quizColl),
      where("difficulty", "==", +baseDifficulty),
      where("isTrial", "==", true)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  }
  localStorage.setItem("currentWord", JSON.stringify(data));
  window.dispatchEvent(new Event("storage"));
  return data;

};

export const fetchQuestionsFromCollection = async (quizColl, baseDifficulty = 0) => {
  let data = [];
  if (baseDifficulty === 0 || !baseDifficulty) {
    const q = query(collection(db, quizColl));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  } else {
    const q = query(
      collection(db, quizColl),
      where("difficulty", "==", +baseDifficulty),
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    data = data.filter((item) => !item.isTrial);
  }
  return data;
};